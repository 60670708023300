.Header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 20px;
    background-color: #990000;
    color: #FFCC00;
    z-index: 1;
}

.profile-container {
    position: relative;
    display: inline-block;
}

.logo2 {
    display: block;
    width: 4%;
    height: 4%;
    min-width: 38px;
}

.logo2 img {
    width: 100%;
    height: 100%;
}

.search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 25%;
}

.search-bar {
    padding: 8px 8px 9px 42px;
    background-color: #990000;
    border: 2px solid #FFCC00;
    border-radius: 50px;
    width: 100%;
    min-width: 130px;
    background-image: url('../../public/magnifier.png'); 
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    color:#FFCC00;
}

.search-bar::placeholder {
    color: #FFCC00;
    opacity: 1;
}

.clear-icon {
    position: absolute;
    right: -3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    visibility: hidden;
}

.clear-icon img {
    width: 65%;
    height: 65%;
}

.search-bar:focus {
    outline: 2px solid #FFCC00;
    background-color:#880202;
}

.sellButton, .shopButton, .logButton, .signupButton {
    padding: 8px 5%;
    border: 2px solid #FFCC00;
    background-color:#990000; 
    color: #FFCC00;
    text-decoration: none;
    border-radius: 20px;
    cursor: pointer;
    white-space: nowrap;
}

.sellButton:hover, .search-bar:hover, .shopButton:hover, .logButton:hover, .signupButton:hover {
    background-color:#880202; 
}

.sellButton, .shopButton, .signupButton {
    margin-right: 6px;
}

.profile-button img {
    width: 44px;
    height: 44px;
}

/* For larger screens */
.button-container {
    display: flex;
    justify-content: space-between;
    width: 37%;
}

.hamburger-icon {
    display: none;
    width: 7%;
}

.button-dropdown  {
    display: none;
}

.hamburger-icon img {
    width: 100%;
    cursor: pointer;
}

/* For smaller screens */
@media (max-width: 660px) {
    .button-container {
        display: none;
    }

    .hamburger-icon {
        position: relative;
        display: inline-block;
    }
    
    .button-dropdown {
        border-radius: 15px;
        display: block;
        background-color: #990000;
        border: 2px solid #FFCC00;
        border-radius: 15px;
        position: absolute;
        right: 11%;
        z-index: 10;
        width: 19%;
        min-width: 100px;
    }
}