.likes-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.listings-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.listing-thumbnail {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.listing-info {
    text-align: left;
    flex-grow: 1;
}

.cost-unlike-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.unlike-icon {
    align-self: flex-end;
    width: 20px;
    height: 20px;
    margin-bottom: 6%;
}
