.edit-listing-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
}

.edit-listing-component {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 550px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
}

.edit-listing-component h2 {
    margin-top: 0;
}

.edit-listing-component label {
    display: block;
    margin: 10px 0;
}

.edit-listing-component input,
.edit-listing-component textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.edit-listing-component select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.edit-listing-component button {
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    
    color: white;
    cursor: pointer;
}


.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.current-image,
.new-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.supplemental-images-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 27px;
}

.supplemental-image-container {
    position: relative;
    width: 150px; 
    height: 150px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.supplemental-image-container input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.supplemental-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-image-button {
    position: absolute;
    top: -5px;
    right: -10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: red;
}

.cancel-button {
    background-color: red;
}

.save-button {
    background-color: #007bff;
}

.mode-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    color: black;
}

.mode-button.active {
    background-color: #007bff;
    color: white;
}

.mode-button.remove {
    background-color: red;
    color: white;
}

.mode-button.disabled {
    background-color: gray;
    cursor: not-allowed;
    color: white;
}

.mode-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.mode-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
}

