.loginPageLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.simple-header {
    padding-top: 5px;
    padding-left: 3%;
    padding-bottom: 5%;
    width: 100%;
}

.loginWrapper {
    display: flex;
    flex-direction: column;
    width: 64%;
    max-width: 600px
}

.intro-text {
    font-weight: 600;
    font-size: 1.75rem;
}

.enterEmail, .enterPassword {
    border-radius: 10px;
    border: 0;
    background-color: #e7e5e5;
    padding: 6px;
    margin: 5px 0;
}

.enterEmail:hover, .enterPassword:hover {
    background-color: #d6d6d5;
}

.enterEmail:focus, .enterPassword:focus {
    outline: 0px;
}

.enterEmail, .enterPassword {
    width: 100%;
}

.loginButton {
    width: 100%; 
    border-radius: 10px;
    border: none;
    background-color: #990000;
    color: white;
    padding: 10px;
    font-size: 16px; 
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.loginButton:hover {
    background-color: #b90505;
}

.SignUpText {
    text-align: center;
    margin-top: 10px;
}

.signUpLink {
    color: black;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.success {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 21%;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background: #EDFBD8;
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
    z-index: 1001;
}

.success__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.success__icon path {
    fill: #84D65A;
}

.success__title {
    font-weight: 500;
    font-size: 14px;
    color: #2B641E;
}

.success__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
}

.success__close path {
    fill: #2B641E;
}

.error-message {
    color: #d9534f;
    font-size: 0.9em;
    margin-top: 5px;
    margin-bottom: 10px;
}

.skyline {
    margin-top: 2%;
    width: 27%;
    max-width: 270px;
}