p, h1 {
    font-family:"SF Pro Text", -apple-system, Helvetica, Arial, "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.shop-listings {
    padding: 4%;
    background-color: #f8f8f8;
}

.shop-listings h1 {
    margin-bottom: 10px;
}

.shop-listings p {
    margin-bottom: 20px;
    font-size: 16px;
    color: #555;
}

.shop-listings-container {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
}

.shop-header {
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    margin: 3%;
    padding-right: 1%;
    margin-bottom: 4%;
    align-items: center;
    justify-content: space-between;
}

.filter-wrapper {
    display: flex;
    flex-direction: column;
}

.filter {
    border: none;
    border-radius: 50px;
    text-decoration: none;
}

.filter:focus, filter:active {
    border: none;
    border-radius: 50px;
    outline: none;
    text-decoration: none;
}

.listing-card {
    text-decoration: none;
    padding: 16px;
    width: calc(24% - 20px);
    box-sizing: border-box;
    margin-bottom: 7%;
}

.listing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-image { 
    object-fit: cover;
    width: 100%;
    height: 66%;
    max-width: 350px;
    max-height: 350px;
    min-width: 100px;
    padding-bottom: 4%;
}

.listing-age p {
    color:#616060;
    font-size: 11px;
    border-bottom: 1px solid #000000;
    padding-bottom: 6%;
}

.listing-title p {
    font-weight: bolder;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;        
}

.listing-cost p {
    font-weight: bold;
    color: #000000;
}

@media (max-width: 600px) {
    .shop-header {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 1200px) {
    .listing-card {
        width: calc(32% - 20px); 
    }
}

@media (max-width: 900px) {
    .listing-card {
        width: calc(48% - 20px);
    }
}

@media (max-width: 600px) {
    .listing-card {
        width: calc(98% - 20px); 
    }

    .filter-container {
        padding: 0px;
    }    
}

.filter-container {
    display: flex;
    align-items: center;
}



#category-select {
    padding: 5px;
    margin-left: 10px;
    font-size: 16px;
}
