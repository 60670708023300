@import './overlay.css';

.error {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 320px;
    padding: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    background: #EF665B;
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
    z-index: 1001;
}

.error__icon {
    width: 20px;
    height: 20px;
    transform: translateY(-2px);
    margin-right: 8px;
}

.error__icon path {
    fill: #fff;
}

.error__title {
    font-weight: 500;
    font-size: 14px;
    color: #fff;
}

.error__close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: auto;
}

.error__close path {
    fill: #fff;
}
