p {
    font-family:"SF Pro Text", -apple-system, Helvetica, Arial, "Segoe UI", Roboto, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.user-profile-page {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 6%;
    padding-bottom: 6%;
}

.profile-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15%;
    gap: 5%;
}

.account-details {
    margin-top: 20px;
    text-align: left;
    width: 100%;
}

.account-details h2 {
    margin-bottom: 10px;
}

.account-details p {
    margin: 5px 0;
}

.profile-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 3%;
    align-items: center;
}

.action-buttons {
    display: flex;
    flex-direction: row;
    gap: 12%;
    margin-top: 2%;
    margin-bottom: 3%;
}

.user-listings {
    margin-top: 7%;
}

.user-listings h2 {
    margin-bottom: 20px;
}

.listings-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.listing-item {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    box-sizing: border-box;
    text-decoration: none;
    padding: 16px;
}

.listing-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.listing-item.delete-mode {
    border-color: red;
    cursor: pointer;
}

.listing-age p {
    color:#616060;
    font-size: 11px;
    border-bottom: 1px solid #000000;
    padding-bottom: 6%;
}

.listing-title p {
    font-weight:bolder;
}

.listing-cost p {
    font-weight: bold;
    color: #000000;
}


.action-button {
    --bg: #FFCC00;
    --text-color: #fff;
    position: relative;
    width: 90px;
    border: none;
    background: var(--bg);
    color: var(--text-color);
    padding: 0.6em;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 5px;
    opacity: 0.8;
    letter-spacing: 1px;
    box-shadow: #cca50b 0px 7px 2px, #000 0px 8px 5px;
}
  
.action-button:hover {
    opacity: 1;
}
  
  .action-button:active {
    top: 4px;
    box-shadow: #cca50b 0px 3px 2px,#000 0px 3px 5px;
  }

.cssbuttons-io-button {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    padding: 0.7em 1.4em 0.7em 1.1em;
    color: white;
    background: #ad5389;
    background: linear-gradient(0deg, rgb(219, 4, 4) 0%, rgb(153, 0, 0) 100%);
    border: none;
    box-shadow: 0 0.7em 1.5em -0.5em #990000;
    letter-spacing: 0.05em;
    border-radius: 20em;
    white-space: nowrap;
  }
  
  .cssbuttons-io-button svg {
    margin-right: 6px;
  }
  
  .cssbuttons-io-button:hover {
    box-shadow: 0 0.5em 1.5em -0.5em #DB0404;
  }
  
  .cssbuttons-io-button:active {
    box-shadow: 0 0.3em 1em -0.5em #DB0404;
  }