/* Not FoundPage.css */

.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #333; 
    padding: 20px;
}

.not-found-title {
    font-size: 10rem;
    margin: 0;
    color: #990000; 
}

.not-found-message {
    font-size: 1.5rem;
    margin: 10px 0;
}

.home-link {
    font-size: 1.2rem;
    color: #ffcc00;
    text-decoration: none;
    border: 2px solid #ffcc00;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.home-link:hover {
    background-color: #ffcc00;
    color: #990000;
}